import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';
import { IEvents, SETTINGS_EVENTS, TabState } from '../../../constants/settings-events';
import {
  createOrdersApi,
  createPlansApi,
  createPremiumApi,
  createBenefitsApi,
  createGroupsApi,
  memoizePlansApi,
} from '../../../services';
import { Analytics } from '../../../services/analytics';
import { PricingPlansBi } from '../../../services/bi';
import { CommonBIDataFromFlowAPI } from '../../../services/CommonBIDataFromFlowAPI';
import { apiHeaders } from '../../../services/headers';
import { AppProps } from '../../../types/common';
import { getUserData } from '../../../utils/user';
import { SettingsReader } from '../DefaultSettingsAdapter';
import settingsParams from '../settingsParams';
import { App } from './AppController';
import { CheckoutController } from './CheckoutController';
import { ListController } from './ListController';
import { Navigation } from './Navigation';
import { RestrictedController } from './RestrictedController';
import { Router } from './Router';
import { StatusController } from './StatusController';

const createController: CreateControllerFn = async (params: ControllerParams) => {
  const {
    flowAPI,
    controllerConfig: {
      setProps: _setProps,
      wixCodeApi,
      appParams: { instance, appDefinitionId, instanceId },
      config,
    },
  } = params;

  // const baseUrl = wixCodeApi.location.baseUrl.split('/').slice(0, 3).join('/');
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const baseUrl = isSSR ? 'https://www.wix.com' : '';

  const headers = apiHeaders({ Authorization: instance });
  const plansApi = createPlansApi(headers, baseUrl);
  const premiumApi = createPremiumApi(flowAPI.httpClient);
  const ordersApi = createOrdersApi(flowAPI.httpClient);
  const benefitsApi = createBenefitsApi(flowAPI.httpClient);
  const groupsApi = createGroupsApi(flowAPI.httpClient);

  const componentEventHandler = createEventHandler<IEvents>(config.publicData.COMPONENT || {});

  const user = getUserData(wixCodeApi.user.currentUser);
  const noop = () => {};
  const { metaSiteId, ownerId, visitorId } = flowAPI.controllerConfig.platformAPIs.bi ?? {};
  const initialProps: AppProps = {
    appInstanceId: instanceId,
    metaSiteId,
    siteOwnerId: ownerId,
    visitorId,
    hidePopup: noop,
    hideToast: noop,
    showToast: noop,
    closeUpgradeModal: noop,
    continueToDemoCheckout: noop,
    instance,
    loginOnCheckout: noop,
    logout: noop,
    navigateToStatus: noop,
    navigateBackToTPA: noop,
    plans: [],
    hasMorePlans: false,
    popup: null,
    selectPlan: noop,
    loadMore: noop,
    selectedPlan: {},
    signupOnCheckout: noop,
    subPage: { name: 'list', integrationData: {} },
    tabState: TabState.REGULAR,
    areMobileSettingsOpened: false,
    user,
    missingPlan: false,
    navigateToHomePage: noop,
    navigateBackToPlanList: noop,
    switchAccounts: noop,
    benefits: [],
    trackInitiateCheckout: noop,
    trackSelectPayment: noop,
    demoBuyNowClicked: noop,
    prices: [],
    navigateBackToCheckout: noop,
    biCheckoutStage: noop,
    biPlanPurchased: noop,
    biThankYouPageCtaButtonClick: noop,
    biUpgradeReferralClick: noop,
    updatePriceDetails: noop,
    updatePriceDetailsError: undefined,
    couponInputMode: 'trigger',
    couponCode: '',
    removeCoupon: noop,
    onBeforeStartPayment: noop,
    onBeforeStartPaymentStatus: undefined,
    updateStartDateError: undefined,
    applyCouponError: undefined,
    hasCoupons: false,
  };

  // For some reason when running integration tests it is not possible to set part of props as missing properties gets
  // reverted to initial set values.
  function setProps<T>(x: T) {
    return _setProps(Object.assign(initialProps, x));
  }

  const nav = new Navigation(wixCodeApi, appDefinitionId);
  const bi = new PricingPlansBi(flowAPI.bi!, new CommonBIDataFromFlowAPI(flowAPI));
  const analytics = new Analytics(wixCodeApi.window);
  const router = new Router(setProps, nav, analytics, wixCodeApi);
  const list = new ListController(
    setProps,
    wixCodeApi,
    flowAPI,
    router,
    memoizePlansApi(plansApi),
    ordersApi,
    premiumApi,
    bi,
    analytics,
    new SettingsReader(flowAPI.settings, settingsParams),
  );
  const checkout = new CheckoutController(
    setProps,
    wixCodeApi,
    router,
    flowAPI,
    plansApi,
    ordersApi,
    benefitsApi,
    premiumApi,
    analytics,
    bi,
  );
  const status = new StatusController(setProps, wixCodeApi, ordersApi, flowAPI, bi, router, plansApi);
  const restricted = new RestrictedController(setProps, wixCodeApi, router, groupsApi, flowAPI);
  const app = new App(setProps, list, checkout, status, restricted, router, flowAPI, bi);

  router.whenInit({ list: async ({ biOptions }) => analytics.setReferralInfo(biOptions?.referralInfo) });
  router.whenNavigate({
    list: async ({ integrationData: { biOptions } }) => analytics.setReferralInfo(biOptions?.referralInfo),
  });

  return {
    async pageReady() {
      // XXX: call to setProps crashes in Editor if done before pageReady().
      setProps(initialProps);

      componentEventHandler.on(SETTINGS_EVENTS.TAB_STATE, (tabState: TabState) => app.setProps({ tabState }));
      componentEventHandler.on(SETTINGS_EVENTS.ARE_MOBILE_SETTINGS_OPENED, (areMobileSettingsOpened: boolean) =>
        app.setProps({ areMobileSettingsOpened }),
      );
      componentEventHandler.onReset(() => app.setProps({ tabState: TabState.REGULAR, areMobileSettingsOpened: false }));

      wixCodeApi.location.onChange(async () => router.initialize());
      wixCodeApi.site.onInstanceChanged(({ instance: newInstance }) => {
        headers.Authorization = newInstance;
        setProps({ instance: newInstance });
      }, appDefinitionId);

      await app.initialize();
    },
    async updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
      await list.fetchPlans();
    },
  };
};

export default createController;
